import { useState, useEffect } from 'react'
import 'isomorphic-fetch'
import isInBrowser from '@helpers/is-in-browser'
import mockPricingData from '@variables/pricing-mock.json'

// prettier-ignore
const regionData = {
  at: 'de-AT', au: 'en-AU', be: 'nl-BE', ca: 'en-CA', ch: 'de-CH', cz: 'cs-CZ', de: 'de-DE', dk: 'da-DK', es: 'es-ES', fi: 'fi-FI', fr: 'fr-FR', gb: 'en-GB', hu: 'hu-HU', 
  ie: 'ga-IE', in: 'en-IN', it: 'it-IT', jp: 'ja-JP', kr: 'ko-KR', mx: 'es-MX', my: 'en-MY', nl: 'en-NL', no: 'no-NO', nz: 'en-NZ', pl: 'pl-PL', pt: 'pt-PT', se: 'sv-SE', sg: 'en-SG',
  
  hk: 'en-AU',
  ae: 'en-AE',
  eu: 'en-NL',
  amer: 'es-AR',
  apac: 'en-AU',
  emea: 'en-GB',
}

// ********************************
// Use these vars in testing to show preview data and control the locale if desired.
const previewEnv = false
const previewLocale = null
const mockData = false
// ********************************

const baseURL = `https://damassets.autodesk.net/ppg${
  previewEnv ? '/stg' : ''
}/prices`

const usePricingService = (plc, locale, useMockData) => {
  const [priceData, setPriceData] = useState()
  let location = null

  if (isInBrowser) {
    location = window.location.href
  }

  const country =
    // Exception for UAE where DOCS is not offered and no data is returned from PDS or PPG
    // UAE for DOCS sees US pricing
    (!((previewLocale === 'ae' || locale === 'ae') && plc === 'BM36DP') &&
      regionData[previewLocale || locale]) ||
    'en-US'
  const customCartParam = `&customizedCart=autodesk-construction-cloud-cart&from=${location}`
  useEffect(() => {
    const requestUrl = `${baseURL}/${plc}/${country}/commercial_single_user.json`

    if (
      (mockData || useMockData) &&
      window?.location?.href.match(
        /deploy-preview|localhost|local\.autodesk\.com/
      )
    ) {
      setPriceData(mockPricingData)
    } else if (plc) {
      fetch(requestUrl)
        .then((res) => res.json())
        .then((data) => {
          setPriceData(data)
        })
        .catch((err) =>
          console.warn(err, 'Could not fetch data from pricing service')
        )
    }
  }, [country, customCartParam, plc, useMockData])
  return priceData
}
export default usePricingService
