import makeStyles from '@material-ui/styles/makeStyles'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  priceRoot: {
    alignItems: ({ alignRight }) => (alignRight ? 'flex-end' : 'flex-start'),
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: theme.typography.fontWeightBold,
    gap: '2px',
  },
  priceContainer: {
    display: 'flex',
    gap: '8px',
  },
  price: {
    alignItems: 'center',
    color: ({ isDiscounted }) =>
      isDiscounted ? theme.palette.text.iris : 'inherit',
    display: 'flex',
    gap: '4px',
    lineHeight: '1',
    [theme.breakpoints.up('md')]: {
      gap: ({ sizeLarge }) => (sizeLarge ? '6px' : '4px'),
    },
  },
  priceValue: {
    fontSize: '28px',
    [theme.breakpoints.up('md')]: {
      fontSize: '35px',
    },
  },
  pricePeriod: {
    fontSize: '16px',
    marginTop: '8px',
    [theme.breakpoints.up('md')]: {
      fontSize: ({ sizeLarge }) => (sizeLarge ? '26px' : '16px'),
    },
  },
  priceDiscount: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '6px',
    padding: '0 4px',
    position: 'relative',
  },
  priceDiscountValue: {
    fontSize: '16px',
    [theme.breakpoints.up('md')]: {
      fontSize: ({ sizeLarge }) => (sizeLarge ? '26px' : '16px'),
    },
  },
  priceDiscountStrikethrough: {
    backgroundColor: theme.palette.text.iris,
    height: '2px',
    position: 'absolute',
    width: '100%',
  },
  priceDetail: {
    color: '#828282',
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      fontSize: ({ sizeLarge }) => (sizeLarge ? '22px' : '14px'),
    },
  },
}))

const Price = ({
  alignRight,
  annualBilling,
  formattedPrices,
  hasTaxText,
  hideAnnualizedPricing,
  sizeLarge,
}) => {
  const classes = useStyles({
    alignRight,
    isDiscounted: formattedPrices.discountPercent,
    sizeLarge,
  })

  const renderPriceValue = () => {
    if (hideAnnualizedPricing) {
      return formattedPrices[
        annualBilling ? 'annualPricePerMonth' : 'monthlyPricePerMonth'
      ]
    }

    return formattedPrices[
      annualBilling ? 'annualPricePerYear' : 'monthlyPricePerYear'
    ]
  }

  return (
    <div className={classes.priceRoot}>
      <div className={classes.priceContainer}>
        <div className={classes.price}>
          <span className={classes.priceValue}>{renderPriceValue()}</span>
          <span className={classes.pricePeriod}>
            {hideAnnualizedPricing ? '/mo' : '/yr'}
          </span>
          {hasTaxText ? '*' : null}
        </div>
        {formattedPrices.discountPercent ? (
          <div className={classes.priceDiscount}>
            {annualBilling
              ? formattedPrices.annualPriceBeforeDiscount
              : formattedPrices.monthlyPriceBeforeDiscount}
            <span className={classes.priceDiscountStrikethrough} />
          </div>
        ) : null}
      </div>
      {hideAnnualizedPricing ? null : (
        <div className={classes.priceDetail}>
          {`
            (${
              formattedPrices[
                annualBilling ? 'annualPricePerMonth' : 'monthlyPricePerMonth'
              ]
            }/mo)
          `}
          {hasTaxText ? '*' : null}
        </div>
      )}
    </div>
  )
}

Price.propTypes = {
  alignRight: PropTypes.bool,
  annualBilling: PropTypes.bool,
  formattedPrices: PropTypes.object.isRequired,
  hasTaxText: PropTypes.bool,
  hideAnnualizedPricing: PropTypes.bool,
  sizeLarge: PropTypes.bool,
}

export default Price
